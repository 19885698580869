import Error404 from "./404";
import React from "react";
function Error({ statusCode }: any) {
  return <>{statusCode == 404 && <Error404></Error404>}</>;
}

Error.getInitialProps = async (props: any) => {
  const { res, err, locale = "es" } = props;

  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;

  return {
    statusCode,
    messages: (await import(`../lang/${locale}.json`)).default,
  };
};

export default Error;
